import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
//import ServiceData  from "~data/startup/service"
import Content from './style'
import image1 from "~assets/image/media/AiDigest/svg/Time-SavingEfficiency.svg";
import image2 from "~assets/image/media/AiDigest/svg/PersonalizedOutreach.svg";
import image3 from "~assets/image/media/AiDigest/svg/EnhancedEngagement.svg";
import image4 from "~assets/image/media/AiDigest/svg/Increased-Success-Rate.svg";
import  ServicesCard from "../../../affiliates/ServicesOne/Components/Card"
import { SuperTag } from '~components';

export default function Benefits({backgroundColor,colClasses,...rest}){
return(
<Content {...rest}>
  <Container>

    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Content.TextBlock>
        <Content.Title as="h2"> <SuperTag value="Benefits of AI Digest for Your Prospecting Efforts:"/> </Content.Title>
        
      </Content.TextBlock>
    </Row>
    
    <Row className="justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-duration={500} data-aos-delay={500} data-aos-once="true">
      {/* Single Services */}
      
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Time-Saving Efficiency"
                  text="AI Digest streamlines the process of gathering and understanding information about your prospects, saving you precious time and allowing you to focus on more critical tasks."
                  image={image1}
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Personalized Outreach"
                  text="With the insights provided by AI Digest, you can create personalized messages that make a lasting impact on your prospects, leading to higher response rates and conversions."
                  image={image2}
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Enhanced Engagement"
                  text="By referencing relevant details from your prospect's profile and past interactions, AI Digest helps create meaningful conversations, fostering stronger connections and rapport with prospects."
                  image={image3}
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Increased Conversion Rate"
                  text="AI Digest empowers you to make data-driven decisions, leading to more effective prospecting strategies and ultimately increasing your conversion rate."
                  image={image4}
                  iconBackground="#F3F4F6"
                />
              </Col>
              
            
      {/*/ .Single Services */}
    </Row>

  </Container>
</Content>

)
}