import React from 'react'
import Content from './style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/AiDigest/svg/Profile-Digest.svg";

export default function ProfileInfo({}){

return(
<Content>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Get Detailed Profile Summaries"/> </Content.Title>

          <Content.Text> <SuperTag value="Say goodbye to the days of manually scanning prospects’ LinkedIn profiles to find relevant information. Instead, AI Digest will intelligently analyze the critical details from any profile to present you with a concise summary of that prospect's professional achievements, skills, interests, and relevant connections. This empowers you to craft a highly personalized outreach message, increasing the likelihood of receiving a response."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Get Started for Free</Content.Button>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>
  </Container>
</Content>
)
}