import React from 'react'
import Content from '../Box-1/style'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/AiDigest/svg/Generate-Message.svg";

export default function MessageGen({}){

return(
<Content>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Personalized Message Generation"/> </Content.Title>

          <Content.Text> <SuperTag value="Using the data gathered from your prospect's LinkedIn profiles and conversation threads, AI Digest crafts highly relevant messages that resonate with your leads. Say goodbye to generic and impersonal prospecting messages – AI Digest ensures your outreach is authentic, engaging, and tailored to each prospect, increasing your chances of building fruitful relationships."/> </Content.Text>
          <Content.Button target="_blank" to="//account.we-connect.io/signup">Get Started for Free</Content.Button>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>
  </Container>
</Content>
)
}